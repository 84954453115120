.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;
  padding: 0 30px 30px 30px;
  width: 900px;
  overflow: scroll;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 40px;
}

.button {
  cursor: pointer;
  border: solid 1px #d9d9d9;
  background-color: #fff;
  padding: 4px 8px;
}

.uploadWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.uploadButton {
  cursor: pointer;
  border: solid 1px #d9d9d9;
  background-color: #fff;
  padding: 6px 8px;
  max-width: 140px;
}
